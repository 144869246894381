import axios from "axios";
import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";


const Login = () => {
  const [show,setShow] = useState(false)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr,setEmailErr] = useState('')
  const [passErr,setPassErr] = useState('') 
  const loginPost = (e) => {
    e.preventDefault();

    axios.post("https://new.mycars.ae/api/login.php", {
      username: email,
      password: password
    })
    .then((res) => {
      const token = res.data.token;
      const errMessage = res.data.message ;
      if (token == undefined) {
        localStorage.removeItem("jwtToken");
      } else {
        localStorage.setItem("jwtToken", token);
      }
      if (errMessage === "Şifrə yanlışdır") {
        setPassErr(errMessage)
        setEmailErr('')
      } else if (errMessage === "İstifadəçi tapılmadı") {
        setPassErr('')
        setEmailErr(errMessage)
      }

      const decodedToken = jwtDecode(token);
      if (decodedToken) {
        window.location.reload() ;
      }
    })  
    .catch((err) => {
      console.log("Hata:", err);
    });
  };
  const showIcon=()=>{
    setShow(!show)
  }

  return (
    
    <>
      <div class="auth-page-wrapper" style={{ background: "#1A1D21" }}>
        <div class="auth-page-content">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="text-center mt-sm-5  text-white-50">
                  <div>
                    <a href="index.html" class="d-inline-block auth-logo">
                      <img
                        src="https://mygroup.ae/logo_b.png"
                        alt=""
                        height="100"
                      />
                    </a>
                  </div>
                  <p class="mt-3 fs-15 fw-medium">
                    My Group
                  </p>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card">
                  <div class="card-body p-4">
                    <div class="text-center mt-2">
                      <h5 class="text-primary">Join the community of MyGroup</h5>
                      <p class="text-muted">Your imagination has no limits</p>
                    </div>
                    <div class="p-2 mt-4">
                      <form onSubmit={loginPost}>
                        <div class="mb-3">
                          <label for="username" class="form-label">
                            Username
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="username"
                            placeholder="Enter username"
                            onChange={(e)=>setEmail(e.target.value)}
                          />
                          {
                              emailErr && (
                                <span className="invalid_message">{emailErr}</span>
                              )
                          }
                        </div>

                        <div class="mb-3">
                          <div class="float-end">
                            <a
                              href="auth-pass-reset-basic.html"
                              class="text-muted"
                            >
                              Forgot password?
                            </a>
                          </div>
                          <label class="form-label" for="password-input">
                            Password
                          </label>
                          <div class="position-relative auth-pass-inputgroup mb-3">
                            <input
                              type={`${show ? "text" : "password"}`}
                              class="form-control pe-5 password-input"
                              placeholder="Enter password"
                              id="password-input"
                              onChange={(e)=>setPassword(e.target.value)}
                            />
                            {
                              passErr && (
                                <span className="invalid_message">{passErr}</span>
                              )
                            }
                            <a
                              class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                              type="submit"
                              id="password-addon"
                              onClick={showIcon}
                            >
                              {/* <i class="ri-eye-fill align-middle"></i> */}
                              <i className={`fa-regular fa-eye${show ? "-slash" : ""}`}></i>
                            </a>
                          </div>
                        </div>

                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="auth-remember-check"
                          />
                          <label
                            class="form-check-label"
                            for="auth-remember-check"
                          >
                            Remember me
                          </label>
                        </div>

                        <div class="mt-4">
                          <button class="btn btn-secondary w-100" type="submit">
                            Sign In
                          </button>
                        </div>

                       
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

export const handleSort = ({ data, toggle, sortBy }) => {
    const sorted = [...data].sort((a, b) => {
        if (sortBy === "mid") {
            return toggle ? a.mid - b.mid : b.mid - a.mid;
        } else if (sortBy === "priceMonth") {
            return toggle ? a.priceMonth - b.priceMonth : b.priceMonth - a.priceMonth;
        } else if (sortBy === "date") {
            return toggle ? b.date - a.date : a.date - b.date;
        } else if (sortBy === "value") {
            return toggle ? a.value - b.value : b.value - a.value;
        } else if (sortBy === "selectedMonth") {
            return toggle ? a.selectedMonth - b.selectedMonth : b.selectedMonth - a.selectedMonth;
        }
         else if(sortBy === "email"){
           return toggle  ? a.email.localeCompare(b.email)
           : b.email.localeCompare(a.email);
         }
         else if (sortBy === "phone") {
            if (a.phone && b.phone) {
                return toggle
                    ? a.phone.localeCompare(b.phone)
                    : b.phone.localeCompare(a.phone);
            } else if (a.mobile && b.mobile) {
                return toggle
                    ? a.mobile.localeCompare(b.mobile)
                    : b.mobile.localeCompare(a.mobile);
            } else {
              
                return 0;
            }
                
        } else if (sortBy === "name") {
           
            if (a.name && b.name) {
                return toggle
                    ? a.name.localeCompare(b.name)
                    : b.name.localeCompare(a.name);
            } else if (a.firstName && b.firstName) {
                return toggle
                    ? a.firstName.localeCompare(b.firstName)
                    : b.firstName.localeCompare(a.firstName);
            } else {
              
                return 0;
            }
        } else {
            return 0;
        }
    });
    return sorted;
};

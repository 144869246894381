import React from 'react'

const Home = () => {
  return (
    <>
    <div className='main-content'>
      <div className="page-content">
        <div className="container-fluid">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 d-flex justify-content-center flex-column align-items-center">
            <img src="https://mygroup.az/logo_b.png " alt="" className="img-fluid logo logo-light" style={{width:"350px",height:"auto",objectFit:"cover"}} />
            <img src="https://mygroup.ae/logo_bk.png" alt="" className="img-fluid logo logo-dark" style={{width:"350px",height:"auto",objectFit:"cover"}} />
            <h1 className="text-center mygrouph1 logo logo-dark" style={{opacity:"0.5",fontSize:"80px",color:"#000"}}>MY GROUP</h1>
            <h1 className="text-center mygrouph1 logo logo-light" style={{opacity:"0.4",fontSize:"80px"}}>MY GROUP</h1>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Home

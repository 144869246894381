// Pagination.jsx

import React from 'react';
import ReactPaginate from 'react-paginate';

function Pagination({ pageCount, onPageChange }) {
  const handlePageClick = ({ selected }) => {
    window.scrollTo({ top: 500, behavior: "smooth" });
    onPageChange(selected);
  };

  return (
    <ReactPaginate
      pageCount={pageCount}
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      onPageChange={handlePageClick}
      containerClassName={"pagination"}
      activeClassName={"active"}
      pageClassName={"inActive"}
      breakLabel={"..."}
      nextLabel={"Next"}
      previousLabel={"Previous"}
      breakClassName="break"
    />
  );
}

export default Pagination;

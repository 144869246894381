import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "../component/Footer";
import Order from "../pages/mycars/Order";
import Home from "../component/Home";
import Profile from "../admin/Profile";
import Login from "../admin/Login";
import Make from "../pages/Make";
import Model from "../pages/Model";
import Test from "../pages/Test";
import Update from "../pages/Update";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import OrderVertu from "../pages/vertuaz/OrderVertu";

const AppRouter = () => {
  const [login, setLogin] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    
    const jwtToken = token && jwtDecode(token);
    if (token && jwtToken && jwtToken.data.username) {
      console.log(token);
      setLogin(true);
      axios.get("https://new.mycars.ae/api/protected.php", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res)=>{
         console.log("ugurlu :",res);
      })
      .catch((err)=>{
        console.log("XETA :",err);
      })
    }
  }, []);
  
  return (
    <>
      <BrowserRouter>
        {login ? (
          <>
            <Header />
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/order" element={<Order />}></Route>
              <Route path="/orderv" element={<OrderVertu />}></Route>
              <Route path="/admin" element={<Profile />}></Route>

              {/* <Route path="/make" element={<Make />}></Route>
              <Route path="/model" element={<Model />}></Route> */}
              {/* <Route path="/test" element={<Test />}></Route>
              <Route path="/update" element={<Update />}></Route> */}
            </Routes>
            <Footer />
          </>
        ) : (
          <Login />
        )}
      </BrowserRouter>
    </>
  );
};

export default AppRouter;

import React, { useEffect, useState } from "react";
import { handleSearch } from "../utils/search";
import { handleSort } from "../utils/sort";
import axios from "axios";
import Pagination from "../utils/Pagination";

const Tables = ({api}) => {
  const [selected, setSelected] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [idToggle, setIdToggle] = useState(true);
  const [phoneToggle, setPhoneToggle] = useState(true);
  const [nameToggle, setNameToggle] = useState(true);
  const [priceMonthToggle, setPriceMonthToggle] = useState(true);
  const [dateToggle, setDateToggle] = useState(true);
  const [valueToggle, setValueToggle] = useState(true);
  const [selectedMonthToggle, setSelectedMonthToggle] = useState(true);
  // Select
  const [selectSelected,setSelectSelected] = useState(6) ;
  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = selectSelected;

  useEffect(() => {
    axios
      .get(`${api}`)
      .then((res) => {
        console.log(res.data);
        setFilteredData(res.data);
        setOrder(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);
  
  const handleSearchWrapper = (searchTerm) => {
    const filtered = handleSearch(order, searchTerm);
    setFilteredData(filtered);
  };
  
  const sort_id = () => {
    const sort = handleSort({
      data: filteredData,
      toggle: idToggle,
      sortBy: "mid",
    });
    setFilteredData(sort);
    setIdToggle(!idToggle);
  };

  const sort_phone = () => {
    const sortedPhone = handleSort({
      data: filteredData,
      toggle: phoneToggle,
      sortBy: "phone",
    });
    setFilteredData(sortedPhone);
    setPhoneToggle(!phoneToggle);
  };

  const sort_name = () => {
    const sorted_name = handleSort({
      data: filteredData,
      toggle: nameToggle,
      sortBy: "name",
    });
    setFilteredData(sorted_name);
    setNameToggle(!nameToggle);
  };

  const sort_price_month = () => {
    const sorted_price = handleSort({
      data: filteredData,
      toggle: priceMonthToggle,
      sortBy: "priceMonth",
    });
    setPriceMonthToggle(!priceMonthToggle);
    setFilteredData(sorted_price);
  };

  const sort_date = () => {
    const sorted_date = handleSort({
      data: filteredData,
      toggle: dateToggle,
      sortBy: "date",
    });
    setDateToggle(!dateToggle);
    setFilteredData(sorted_date);
  };

  const sort_value = () => {
    const sorted_value = handleSort({
      data: filteredData,
      toggle: valueToggle,
      sortBy: "value",
    });
    setValueToggle(!valueToggle);
    setFilteredData(sorted_value);
  };

  const sort_month = () => {
    const sorted_month = handleSort({
      data: filteredData,
      toggle: selectedMonthToggle,
      sortBy: "selectedMonth",
    });
    setSelectedMonthToggle(!selectedMonthToggle);
    setFilteredData(sorted_month);
  };

  // Pagination
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };
  
  const offset = currentPage * itemsPerPage;
  const currentData = filteredData.slice(offset, offset + itemsPerPage);
  
  const selectedFunc=(e)=>{
    const selected = e.target.value ;
    setSelectSelected(selected)
  }



  return (
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0">Order</h4>

                <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item">
                      <a href="javascript: void(0);">Dashboard</a>
                    </li>
                    <li class="breadcrumb-item active">Order</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title mb-0">Add, Edit & Remove</h4>
                </div>

                <div class="card-body">
                  <div class="listjs-table" id="customerList">
                    <div class="row g-4 mb-3">
                      <div class="col-sm-auto">
                        <div>
                          <button
                            type="button"
                            class="btn btn-success add-btn me-2"
                            data-bs-toggle="modal"
                            id="create-btn"
                            data-bs-target="#showModal"
                          >
                            <i class="ri-add-line align-bottom me-1"></i> Add
                          </button>
                          <button
                            class="btn btn-soft-danger"
                            onClick="deleteMultiple()"
                          >
                            <i class="ri-delete-bin-2-line"></i>
                          </button>
                        </div>
                      </div>
                      <div class="col-sm">
                        <div class="d-flex justify-content-sm-end">
                          <div className="result">
                            <select 
                                name="" 
                                id="" 
                                className="h-100 px-2"
                                onChange={selectedFunc}
                                >
                              <option value="6">6</option>
                              <option value="12">12</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                            </select>
                          </div>
                          <div class="search-box ms-2">
                            <input
                              type="text"
                              class="form-control search"
                              placeholder="Search..."
                              onChange={(e) =>
                                handleSearchWrapper(e.target.value)
                              }
                            />
                            <i class="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="table-responsive table-card mt-3 mb-1">
                      <table
                        class="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead class="table-light">
                          <tr>
                            <th scope="col" style={{ width: "50px" }}>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="checkAll"
                                  value="option"
                                />
                              </div>
                            </th>
                            <th style={{ cursor: "pointer" }} onClick={sort_id}>
                              <div className="d-flex justify-content-between">
                                Order id
                                <div className="d-flex flex-column ms-2 mt-1">
                                  <i
                                    class="fa-solid fa-caret-up"
                                    style={{
                                      fontSize: "10px",
                                      color: `${idToggle ? "#00C2CB" : "#000"}`,
                                    }}
                                  ></i>
                                  <i
                                    class="fa-solid fa-caret-down"
                                    style={{
                                      fontSize: "10px",
                                      color: `${
                                        idToggle !== true ? "#00C2CB" : "#000"
                                      }`,
                                      transform: "translateY(-4px)",
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </th>

                            <th
                              style={{ cursor: "pointer" }}
                              onClick={sort_name}
                            >
                              <div className="d-flex justify-content-between">
                                Ad
                                <div className="d-flex  flex-column ms-2 mt-1">
                                  <i
                                    class="fa-solid fa-caret-up"
                                    style={{
                                      fontSize: "10px",
                                      color: `${
                                        nameToggle ? "#00C2CB" : "#000"
                                      }`,
                                    }}
                                  ></i>
                                  <i
                                    class="fa-solid fa-caret-down"
                                    style={{
                                      fontSize: "10px",
                                      color: `${
                                        nameToggle !== true ? "#00C2CB" : "#000"
                                      }`,
                                      transform: "translateY(-4px)",
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={sort_value}
                            >
                              <div className="d-flex justify-content-between">
                                İlkin ödəniş
                                <div className="d-flex  flex-column ms-2 mt-1">
                                  <i
                                    class="fa-solid fa-caret-up"
                                    style={{
                                      fontSize: "10px",
                                      color: `${
                                        valueToggle ? "#00C2CB" : "#000"
                                      }`,
                                    }}
                                  ></i>
                                  <i
                                    class="fa-solid fa-caret-down"
                                    style={{
                                      fontSize: "10px",
                                      color: `${
                                        valueToggle !== true
                                          ? "#00C2CB"
                                          : "#000"
                                      }`,
                                      transform: "translateY(-4px)",
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </th>

                            <th
                              style={{ cursor: "pointer" }}
                              onClick={sort_phone}
                            >
                              <div className="d-flex justify-content-between">
                                Mobil
                                <div className="d-flex  flex-column ms-2 mt-1">
                                  <i
                                    class="fa-solid fa-caret-up"
                                    style={{
                                      fontSize: "10px",
                                      color: `${
                                        phoneToggle ? "#00C2CB" : "#000"
                                      }`,
                                    }}
                                  ></i>
                                  <i
                                    class="fa-solid fa-caret-down"
                                    style={{
                                      fontSize: "10px",
                                      color: `${
                                        phoneToggle !== true
                                          ? "#00C2CB"
                                          : "#000"
                                      }`,
                                      transform: "translateY(-4px)",
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={sort_date}
                            >
                              <div className="d-flex justify-content-between">
                                Tarix
                                <div className="d-flex  flex-column ms-2 mt-1">
                                  <i
                                    class="fa-solid fa-caret-up"
                                    style={{
                                      fontSize: "10px",
                                      color: `${
                                        dateToggle ? "#00C2CB" : "#000"
                                      }`,
                                    }}
                                  ></i>
                                  <i
                                    class="fa-solid fa-caret-down"
                                    style={{
                                      fontSize: "10px",
                                      color: `${
                                        dateToggle !== true ? "#00C2CB" : "#000"
                                      }`,
                                      transform: "translateY(-4px)",
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={sort_price_month}
                            >
                              <div className="d-flex justify-content-between">
                                Aylara görə
                                <div className="d-flex  flex-column ms-2 mt-1">
                                  <i
                                    class="fa-solid fa-caret-up"
                                    style={{
                                      fontSize: "10px",
                                      color: `${
                                        priceMonthToggle ? "#00C2CB" : "#000"
                                      }`,
                                    }}
                                  ></i>
                                  <i
                                    class="fa-solid fa-caret-down"
                                    style={{
                                      fontSize: "10px",
                                      color: `${
                                        priceMonthToggle !== true
                                          ? "#00C2CB"
                                          : "#000"
                                      }`,
                                      transform: "translateY(-4px)",
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={sort_month}
                            >
                              <div className="d-flex justify-content-between">
                                Seçilən ay
                                <div className="d-flex  flex-column ms-2 mt-1">
                                  <i
                                    class="fa-solid fa-caret-up"
                                    style={{
                                      fontSize: "10px",
                                      color: `${
                                        selectedMonthToggle ? "#00C2CB" : "#000"
                                      }`,
                                    }}
                                  ></i>
                                  <i
                                    class="fa-solid fa-caret-down"
                                    style={{
                                      fontSize: "10px",
                                      color: `${
                                        selectedMonthToggle !== true
                                          ? "#00C2CB"
                                          : "#000"
                                      }`,
                                      transform: "translateY(-4px)",
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </th>
                            <th>
                              <div className="d-flex justify-content-between">
                                Action
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody class="list form-check-all">
                          {loading ? (
                            <tr>
                              <td colSpan="8" className="text-center">
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{ minHeight: "100px" }}
                                >
                                  <div className="spinner ms-5"></div>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            currentData.length > 0 &&
                            currentData.map((item, id) => {
                              const time_milliseconds = Number(
                                item.date + "000"
                              );
                              const date = new Date(time_milliseconds);
                              const formatted_date = date.toLocaleDateString();
                              return (
                                <tr key={id}>
                                  <th scope="row">
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="chk_child"
                                        value="option1"
                                      />
                                    </div>
                                  </th>

                                  <td class="id">
                                    <a
                                      // href="javascript:void(0);"
                                      class="fw-medium link-primary"
                                    >
                                      #{item.mid}
                                    </a>
                                  </td>
                                  <td class="customer_name">{item.name}</td>
                                  <td class="email">${item.value}</td>
                                  <td class="phone">{item.phone}</td>
                                  <td class="date">{formatted_date}</td>
                                  <td class="price">${item.priceMonth}</td>
                                  <td class="month">{item.selectedMonth}</td>

                                  {/* <td class="status">
                                    <span
                                      class={`badge bg-${
                                        item.active == "active"
                                          ? "success"
                                          : "danger"
                                      }-subtle text-${
                                        item.active == "active"
                                          ? "success"
                                          : "danger"
                                      } text-uppercase`}
                                    >
                                      {item.active}
                                    </span>
                                  </td> */}

                                  <td>
                                    <div class="d-flex gap-2">
                                      <div class="edit">
                                        <button
                                          class="btn btn-sm btn-success edit-item-btn"
                                          data-bs-toggle="modal"
                                          data-bs-target="#showModal"
                                          onClick={() => setSelected(item)}
                                        >
                                          Edit
                                        </button>
                                      </div>
                                      <div class="remove">
                                        <button
                                          class="btn btn-sm btn-danger remove-item-btn"
                                          data-bs-toggle="modal"
                                          data-bs-target="#deleteRecordModal"
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                      {loading
                        ? ""
                        : filteredData.length == 0 && (
                            <div class="noresult" style={{ display: "block" }}>
                              <div class="text-center">
                                <lord-icon
                                  src="https://cdn.lordicon.com/unukghxb.json"
                                  trigger="loop"
                                  delay="500"
                                  style={{ width: "100px", height: "100px" }}
                                ></lord-icon>
                                <h5 class="mt-2">Sorry! No Result Found</h5>
                                <p class="text-muted mb-0">
                                  We've searched more than 150+ Orders We did
                                  not find any orders for you search.
                                </p>
                              </div>
                            </div>
                          )}
                    </div>
                    {
                      filteredData.length !== 0 && (
                     <div className="d-flex justify-content-between">
                     <div>Showing 1-{selectSelected} of {filteredData.length} results</div>
                      <div class="">
                          <Pagination
                            pageCount={Math.ceil(
                              filteredData.length / itemsPerPage
                            )}
                            onPageChange={handlePageChange}
                          />
                    </div>
                     </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 
            <div class="row">
                <div class="col-xl-4">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title mb-0">Data Attributes + Custom</h4>
                        </div>

                        <div class="card-body">
                            <p class="text-muted">Use data attributes and other custom attributes as keys</p>
                            <div id="users">
                                <div class="row mb-2">
                                    <div class="col">
                                        <div>
                                            <input class="search form-control" placeholder="Search" />
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn btn-light sort" data-sort="name">
                                            Sort by name
                                        </button>
                                    </div>
                                </div>

                                <div data-simplebar style={{height:"242px"}} class="mx-n3">
                                    <ul class="list list-group list-group-flush mb-0">
                                        <li class="list-group-item" data-id="1">
                                            <div class="d-flex">
                                                <div class="flex-grow-1">
                                                    <h5 class="fs-13 mb-1"><a href="#" class="link name text-body">Jonny Stromberg</a></h5>
                                                    <p class="born timestamp text-muted mb-0" data-timestamp="12345">1986</p>
                                                </div>
                                                <div class="flex-shrink-0">
                                                    <div>
                                                        <img class="image avatar-xs rounded-circle" alt="" src="assets/images/users/avatar-1.jpg"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                   
                                        <li class="list-group-item" data-id="2">
                                            <div class="d-flex">
                                                <div class="flex-grow-1">
                                                    <h5 class="fs-13 mb-1"><a href="#" class="link name text-body">Jonas Arnklint</a></h5>
                                                    <p class="born timestamp text-muted mb-0" data-timestamp="23456">1985</p>
                                                </div>
                                                <div class="flex-shrink-0">
                                                    <div>
                                                        <img class="image avatar-xs rounded-circle" alt="" src="assets/images/users/avatar-2.jpg"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                 
                                        <li class="list-group-item" data-id="3">
                                            <div class="d-flex">
                                                <div class="flex-grow-1">
                                                    <h5 class="fs-13 mb-1"><a href="#" class="link name text-body">Martina Elm</a></h5>
                                                    <p class="born timestamp text-muted mb-0" data-timestamp="34567">1986</p>
                                                </div>
                                                <div class="flex-shrink-0">
                                                    <div>
                                                        <img class="image avatar-xs rounded-circle" alt="" src="assets/images/users/avatar-3.jpg"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                      
                                        <li class="list-group-item" data-id="4">
                                            <div class="d-flex">
                                                <div class="flex-grow-1">
                                                    <h5 class="fs-13 mb-1"><a href="#" class="link name text-body">Gustaf Lindqvist</a></h5>
                                                    <p class="born timestamp text-muted mb-0" data-timestamp="45678">1983</p>
                                                </div>
                                                <div class="flex-shrink-0">
                                                    <div>
                                                        <img class="image avatar-xs rounded-circle" alt="" src="assets/images/users/avatar-4.jpg"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                      
                                    </ul>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                 
                </div>
           

                <div class="col-xl-4">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title mb-0">Existing List</h4>
                        </div>

                        <div class="card-body">
                            <p class="text-muted">Basic Example with Existing List</p>
                            <div id="contact-existing-list">
                                <div class="row mb-2">
                                    <div class="col">
                                        <div>
                                            <input class="search form-control" placeholder="Search" />
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn btn-light sort" data-sort="contact-name">
                                            Sort by name
                                        </button>
                                    </div>
                                </div>

                                <div data-simplebar style={{height:"242px"}} class="mx-n3">
                                    <ul class="list list-group list-group-flush mb-0">
                                        <li class="list-group-item" data-id="01">
                                            <div class="d-flex align-items-start">
                                                <div class="flex-shrink-0 me-3">
                                                    <div>
                                                        <img class="image avatar-xs rounded-circle" alt="" src="assets/images/users/avatar-1.jpg"/>
                                                    </div>
                                                </div>

                                                <div class="flex-grow-1 overflow-hidden">
                                                    <h5 class="contact-name fs-13 mb-1"><a href="#" class="link text-body">Jonny Stromberg</a></h5>
                                                    <p class="contact-born text-muted mb-0">New updates for ABC Theme</p>
                                                </div>

                                                <div class="flex-shrink-0 ms-2">
                                                    <div class="fs-11 text-muted">06 min</div>
                                                </div>
                                            </div>
                                        </li>
                                      
                                        <li class="list-group-item" data-id="02">
                                            <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0 me-3">
                                                    <div>
                                                        <img class="image avatar-xs rounded-circle" alt="" src="assets/images/users/avatar-2.jpg"/>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1 overflow-hidden">
                                                    <h5 class="contact-name fs-13 mb-1"><a href="#" class="link text-body">Jonas Arnklint</a></h5>
                                                    <p class="contact-born text-muted mb-0">Bug Report - abc theme</p>
                                                </div>
                                                <div class="flex-shrink-0 ms-2">
                                                    <div class="fs-11 text-muted">12 min</div>
                                                </div>
                                            </div>
                                        </li>
                                      
                                        <li class="list-group-item" data-id="03">
                                            <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0 me-3">
                                                    <div>
                                                        <img class="image avatar-xs rounded-circle" alt="" src="assets/images/users/avatar-3.jpg"/>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h5 class="contact-name fs-13 mb-1"><a href="#" class="link text-body">Martina Elm</a></h5>
                                                    <p class="contact-born text-muted mb-0">Nice to meet you</p>
                                                </div>
                                                <div class="flex-shrink-0 ms-2">
                                                    <div class="fs-11 text-muted">28 min</div>
                                                </div>
                                            </div>
                                        </li>
                                     
                                        <li class="list-group-item" data-id="04">
                                            <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0 me-3">
                                                    <div>
                                                        <img class="image avatar-xs rounded-circle" alt="" src="assets/images/users/avatar-4.jpg"/>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h5 class="contact-name fs-13 mb-1"><a href="#" class="link text-body">Gustaf Lindqvist</a></h5>
                                                    <p class="contact-born text-muted mb-0">I've finished it! See you so</p>
                                                </div>
                                                <div class="flex-shrink-0 ms-2">
                                                    <div class="fs-11 text-muted">01 hrs</div>
                                                </div>
                                            </div>
                                        </li>
                                       
                                    </ul>
                               
                                </div>
                            </div>
                        </div>
                    </div>
     
                </div>


                <div class="col-xl-4">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title mb-0">Fuzzy Search</h4>
                        </div>
                        <div class="card-body">
                            <p class="text-muted">Example of how to use the fuzzy search plugin</p>
                            <div id="fuzzysearch-list">
                                <input type="text" class="fuzzy-search form-control mb-2" placeholder="Search" />

                                <div data-simplebar style={{height:"242px"}}>
                                    <ul class="list mb-0">
                                        <li>
                                            <p class="name">Guybrush Threepwood</p>
                                        </li>
                                        <li>
                                            <p class="name">Elaine Marley</p>
                                        </li>
                                        <li>
                                            <p class="name">LeChuck</p>
                                        </li>
                                        <li>
                                            <p class="name">Stan</p>
                                        </li>
                                        <li>
                                            <p class="name">Voodoo Lady</p>
                                        </li>
                                        <li>
                                            <p class="name">Herman Toothrot</p>
                                        </li>
                                        <li>
                                            <p class="name">Meathook</p>
                                        </li>
                                        <li>
                                            <p class="name">Carla</p>
                                        </li>
                                        <li>
                                            <p class="name">Otis</p>
                                        </li>
                                        <li>
                                            <p class="name">Rapp Scallion</p>
                                        </li>
                                        <li>
                                            <p class="name">Rum Rogers Sr.</p>
                                        </li>
                                        <li>
                                            <p class="name">Men of Low Moral Fiber</p>
                                        </li>
                                        <li>
                                            <p class="name">Murray</p>
                                        </li>
                                        <li>
                                            <p class="name">Cannibals</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         

            <div class="row">
                <div class="col-xl-4">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title mb-0">Pagination</h4>
                        </div>

                        <div class="card-body">
                            <p class="text-muted">Example of how to use the pagination plugin</p>

                            <div class="listjs-table" id="pagination-list">
                                <div class="mb-2">
                                    <input class="search form-control" placeholder="Search" />
                                </div>

                                <div class="mx-n3">
                                    <ul class="list list-group list-group-flush mb-0">
                                        <li class="list-group-item">
                                            <div class="d-flex align-items-center pagi-list">
                                                <div class="flex-shrink-0 me-3">
                                                    <div>
                                                        <img class="image avatar-xs rounded-circle" alt="" src="assets/images/users/avatar-1.jpg"/>
                                                    </div>
                                                </div>

                                                <div class="flex-grow-1 overflow-hidden">
                                                    <h5 class="fs-13 mb-1"><a href="#" class="link text-body">Jonny Stromberg</a></h5>
                                                    <p class="born timestamp text-muted mb-0">Front end Developer</p>
                                                </div>

                                                <div class="flex-shrink-0 ms-2">
                                                    <div>
                                                        <button type="button" class="btn btn-sm btn-light"><i class="ri-mail-line align-bottom"></i> Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                   
                                        <li class="list-group-item">
                                            <div class="d-flex align-items-center pagi-list">
                                                <div class="flex-shrink-0 me-3">
                                                    <div>
                                                        <img class="image avatar-xs rounded-circle" alt="" src="assets/images/users/avatar-2.jpg"/>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1 overflow-hidden">
                                                    <h5 class="fs-13 mb-1"><a href="#" class="link text-body">Jonas Arnklint</a></h5>
                                                    <p class="born fs-12 timestamp text-muted mb-0">Backend Developer</p>
                                                </div>
                                                <div class="flex-shrink-0 ms-2">
                                                    <div>
                                                        <button type="button" class="btn btn-sm btn-light"><i class="ri-mail-line align-bottom"></i> Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                      
                                        <li class="list-group-item">
                                            <div class="d-flex align-items-center pagi-list">
                                                <div class="flex-shrink-0 me-3">
                                                    <div>
                                                        <img class="image avatar-xs rounded-circle" alt="" src="assets/images/users/avatar-3.jpg"/>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h5 class="fs-13 mb-1"><a href="#" class="link text-body">Martina Elm</a></h5>
                                                    <p class="born fs-12 timestamp text-muted mb-0">UI/UX Designer</p>
                                                </div>
                                                <div class="flex-shrink-0 ms-2">
                                                    <div>
                                                        <button type="button" class="btn btn-sm btn-light"><i class="ri-mail-line align-bottom"></i> Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                               
                                        <li class="list-group-item">
                                            <div class="d-flex align-items-center pagi-list">
                                                <div class="flex-shrink-0 me-3">
                                                    <div>
                                                        <img class="image avatar-xs rounded-circle" alt="" src="assets/images/users/avatar-4.jpg"/>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h5 class="fs-13 mb-1"><a href="#" class="link text-body">Gustaf Lindqvist</a></h5>
                                                    <p class="born fs-12 timestamp text-muted mb-0">Full Stack Developer</p>
                                                </div>
                                                <div class="flex-shrink-0 ms-2">
                                                    <div>
                                                        <button type="button" class="btn btn-sm btn-light"><i class="ri-mail-line align-bottom"></i> Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                 
                                        <li class="list-group-item">
                                            <div class="d-flex align-items-center pagi-list">
                                                <div class="flex-shrink-0 me-3">
                                                    <div>
                                                        <img class="image avatar-xs rounded-circle" alt="" src="assets/images/users/avatar-1.jpg"/>
                                                    </div>
                                                </div>

                                                <div class="flex-grow-1 overflow-hidden">
                                                    <h5 class="fs-13 mb-1"><a href="#" class="link text-body">Jonny Stromberg</a></h5>
                                                    <p class="born timestamp text-muted mb-0">Front end Developer</p>
                                                </div>

                                                <div class="flex-shrink-0 ms-2">
                                                    <div>
                                                        <button type="button" class="btn btn-sm btn-light"><i class="ri-mail-line align-bottom"></i> Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                               
                                        <li class="list-group-item">
                                            <div class="d-flex align-items-center pagi-list">
                                                <div class="flex-shrink-0 me-3">
                                                    <div>
                                                        <img class="image avatar-xs rounded-circle" alt="" src="assets/images/users/avatar-2.jpg"/>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1 overflow-hidden">
                                                    <h5 class="fs-13 mb-1"><a href="#" class="link text-body">Jonas Arnklint</a></h5>
                                                    <p class="born fs-12 timestamp text-muted mb-0">Backend Developer</p>
                                                </div>
                                                <div class="flex-shrink-0 ms-2">
                                                    <div>
                                                        <button type="button" class="btn btn-sm btn-light"><i class="ri-mail-line align-bottom"></i> Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    
                                    </ul>
                         

                                    <div class="d-flex justify-content-center">
                                        <div class="pagination-wrap hstack gap-2">
                                            <a class="page-item pagination-prev disabled" href="javascript:void(0)">
                                                Previous
                                            </a>
                                            <ul class="pagination listjs-pagination mb-0"></ul>
                                            <a class="page-item pagination-next" href="javascript:void(0)">
                                                Next
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            
            </div> */}

          <div
            class="modal fade"
            id="showModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header bg-light p-3">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Edit
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="close-modal"
                  ></button>
                </div>
                {selected !== undefined && (
                  <form class="tablelist-form" autocomplete="off">
                    <div class="modal-body">
                      <div
                        class="mb-3"
                        id="modal-id"
                        style={{ display: "none" }}
                      >
                        <label for="id-field" class="form-label">
                          ID
                        </label>
                        <input
                          type="text"
                          id="id-field"
                          class="form-control"
                          placeholder="ID"
                          readonly
                        />
                      </div>

                      <div class="mb-3">
                        <label for="customername-field" class="form-label">
                          Id
                        </label>
                        <input
                          type="text"
                          id="customername-field"
                          class="form-control"
                          value={selected.id}
                          placeholder="Enter Name"
                          required
                        />
                        <div class="invalid-feedback">
                          Please enter a customer name.
                        </div>
                      </div>

                      <div class="mb-3">
                        <label for="customername-field" class="form-label">
                          Ad
                        </label>
                        <input
                          type="text"
                          id="customername-field"
                          class="form-control"
                          value={selected.name}
                          placeholder="Enter Name"
                          required
                          onChange={(e) => {
                            if (selected) {
                              setSelected({
                                ...selected,
                                name: e.target.value,
                              });
                            }
                          }}
                        />
                        <div class="invalid-feedback">
                          Please enter a customer name.
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="phone-field" class="form-label">
                          Mobil
                        </label>
                        <input
                          value={selected.phone}
                          type="text"
                          id="phone-field"
                          class="form-control"
                          placeholder="Enter Phone no."
                          required
                        />
                        <div class="invalid-feedback">
                          Please enter a phone.
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="email-field" class="form-label">
                          İlkin ödəniş
                        </label>
                        <input
                          type="email"
                          value={`$${selected.value}`}
                          id="email-field"
                          class="form-control"
                          placeholder="Enter Email"
                          required
                        />
                        <div class="invalid-feedback">
                          Please enter an email.
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="email-field" class="form-label">
                          Aylara görə
                        </label>
                        <input
                          type="email"
                          value={`$${selected.priceMonth}`}
                          id="email-field"
                          class="form-control"
                          placeholder="Enter Email"
                          required
                        />
                        <div class="invalid-feedback">
                          Please enter an email.
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="email-field" class="form-label">
                          Seçilən ay
                        </label>
                        <input
                          type="email"
                          value={`${selected.selectedMonth}`}
                          id="email-field"
                          class="form-control"
                          placeholder="Enter Email"
                          required
                        />
                        <div class="invalid-feedback">
                          Please enter an email.
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="date-field" class="form-label">
                          Tarix
                        </label>
                        <input
                          value={selected.date}
                          type="text"
                          id="date-field"
                          class="form-control"
                          placeholder="Select Date"
                          required
                        />
                        <div class="invalid-feedback">
                          Please select a date.
                        </div>
                      </div>

                      {/* <div>
                        <label for="status-field" class="form-label">
                          Status
                        </label>
                        <select
                          class="form-control"
                          data-trigger
                          name="status-field"
                          id="status-field"
                          required
                        >
                          <option value={`${selected.active}`} selected>{selected.active}</option>
                        </select>
                      </div> */}
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          class="btn btn-light"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn btn-success"
                          id="edit-btn"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>

          <div
            class="modal fade zoomIn"
            id="deleteRecordModal"
            tabindex="-1"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="btn-close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="mt-2 text-center">
                    <lord-icon
                      src="https://cdn.lordicon.com/gsqxdxog.json"
                      trigger="loop"
                      colors="primary:#f7b84b,secondary:#f06548"
                      style={{ width: "100px", height: "100px" }}
                    ></lord-icon>
                    <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                      <h4>Are you Sure ?</h4>
                      <p class="text-muted mx-4 mb-0">
                        Are you Sure You want to Remove this Record ?
                      </p>
                    </div>
                  </div>
                  <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button
                      type="button"
                      class="btn w-sm btn-light"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="btn w-sm btn-danger "
                      id="delete-record"
                    >
                      Yes, Delete It!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tables;

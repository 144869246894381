import React from "react";

const Footer = () => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
  return (
    <>
      <footer className="footer">
  <div className="container-fluid">
    <div className="row">
      <div className="col-sm-6">
        {year} © MyGroup.
      </div>
      <div className="col-sm-6">
        <div className="text-sm-end d-none d-sm-block">
        © Copyright MyGroup - Sayt müəllif hüquqları ilə qorunur.
        </div>
      </div>
    </div>
  </div>
</footer>

    </>
  );
};

export default Footer;

import React from "react";
import Tables from "../../utils/Tables";

const Order = () => {
  return (
    <>
      <Tables api={"https://mycars.ae/api/v2/orders"} />
    </>
  );
};

export default Order;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  return (
    <>
      <div class="main-content  mt-5">
        <div class="page-content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xxl-3">
                <div class="card mt-n5">
                  <div class="card-body p-4">
                    <div class="text-center">
                      <div class="profile-user position-relative d-inline-block mx-auto  mb-4">
                        <img
                          src="assets/images/users/avatar-1.jpg"
                          class="rounded-circle avatar-xl img-thumbnail user-profile-image"
                          alt="user-profile-image"
                        />
                        <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                          <input
                            id="profile-img-file-input"
                            type="file"
                            class="profile-img-file-input"
                          />
                          <label
                            for="profile-img-file-input"
                            class="profile-photo-edit avatar-xs"
                          >
                            <span class="avatar-title rounded-circle bg-light text-body">
                              <i class="ri-camera-fill"></i>
                            </span>
                          </label>
                        </div>
                      </div>
                      <h5 class="fs-16 mb-1">Name Surname</h5>
                      <p class="text-muted mb-0">Admin</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xxl-9">
                <div class="card mt-xxl-n5">
                  <div class="card-header">
                    <ul
                      class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                      role="tablist"
                    >
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          data-bs-toggle="tab"
                          href="#personalDetails"
                          role="tab"
                        >
                          <i class="fas fa-home"></i> Personal Details
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-bs-toggle="tab"
                          href="#changePassword"
                          role="tab"
                        >
                          <i class="far fa-user"></i> Change Password
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="card-body p-4">
                    <div class="tab-content">
                      <div
                        class="tab-pane active"
                        id="personalDetails"
                        role="tabpanel"
                      >
                        <form action="javascript:void(0);">
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="mb-3">
                                <label for="firstnameInput" class="form-label">
                                  First Name
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="firstnameInput"
                                  placeholder="Enter your firstname"
                                  value="Dave"
                                />
                              </div>
                            </div>

                            <div class="col-lg-6">
                              <div class="mb-3">
                                <label for="lastnameInput" class="form-label">
                                  Last Name
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="lastnameInput"
                                  placeholder="Enter your lastname"
                                  value="Adame"
                                />
                              </div>
                            </div>

                            <div class="col-lg-6">
                              <div class="mb-3">
                                <label
                                  for="phonenumberInput"
                                  class="form-label"
                                >
                                  Phone Number
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="phonenumberInput"
                                  placeholder="Enter your phone number"
                                  value="+(1) 987 6543"
                                />
                              </div>
                            </div>

                            <div class="col-lg-6">
                              <div class="mb-3">
                                <label for="emailInput" class="form-label">
                                  Email Address
                                </label>
                                <input
                                  type="email"
                                  class="form-control"
                                  id="emailInput"
                                  placeholder="Enter your email"
                                  value="admin"
                                />
                              </div>
                            </div>
                    
                            <div class="col-lg-12">
                              <div class="hstack gap-2 justify-content-end">
                                <button type="submit" class="btn btn-secondary">
                                  Updates
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-soft-danger"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>

                      <div class="tab-pane" id="changePassword" role="tabpanel">
                        <form action="javascript:void(0);">
                          <div class="row g-2">
                            <div class="col-lg-4">
                              <div>
                                <label
                                  for="oldpasswordInput"
                                  class="form-label"
                                >
                                  Old Password*
                                </label>
                                <input
                                  type="password"
                                  class="form-control"
                                  id="oldpasswordInput"
                                  placeholder="Enter current password"
                                />
                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div>
                                <label
                                  for="newpasswordInput"
                                  class="form-label"
                                >
                                  New Password*
                                </label>
                                <input
                                  type="password"
                                  class="form-control"
                                  id="newpasswordInput"
                                  placeholder="Enter new password"
                                />
                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div>
                                <label
                                  for="confirmpasswordInput"
                                  class="form-label"
                                >
                                  Confirm Password*
                                </label>
                                <input
                                  type="password"
                                  class="form-control"
                                  id="confirmpasswordInput"
                                  placeholder="Confirm password"
                                />
                              </div>
                            </div>

                            <div class="col-lg-12">
                              <div class="mb-3">
                                <a
                                  href="javascript:void(0);"
                                  class="link-primary text-decoration-underline"
                                >
                                  Forgot Password ?
                                </a>
                              </div>
                            </div>

                            <div class="col-lg-12">
                              <div class="text-end">
                                <button type="submit" class="btn btn-secondary">
                                  Change Password
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;

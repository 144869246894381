import React, { useEffect, useState } from "react";
import { handleSearch } from "../../utils/search";
import { handleSort } from "../../utils/sort";
import axios from "axios";
import Pagination from "../../utils/Pagination";

const OrderVertu = () => {
  const [selected, setSelected] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [idToggle, setIdToggle] = useState(true);
  const [phoneToggle, setPhoneToggle] = useState(true);
  const [nameToggle, setNameToggle] = useState(true);
  const [emailToggle,setEmailToggle] = useState(true)
  const [priceMonthToggle, setPriceMonthToggle] = useState(true);
  const [dateToggle, setDateToggle] = useState(true);
  const [valueToggle, setValueToggle] = useState(true);
  const [selectedMonthToggle, setSelectedMonthToggle] = useState(true);
  // Select
  const [selectSelected, setSelectSelected] = useState(6);
  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = selectSelected;

  useEffect(() => {
    axios
      .get("https://vertuaz.com/api/v2/orders")
      .then((res) => {
        const data = res.data;
        console.log("oreder",data);
        const processedData = data.map((order) => {
          const basket = JSON.parse(order.basket || "[]");

          const idCount = basket.reduce((acc, id) => {
            acc[id] = (acc[id] || 0) + 1;
            return acc;
          }, {});
          const updatedBasket = Object.keys(idCount).map((id) => ({
            id,
            count: idCount[id],
          }));

          return {
            ...order,
            basket: updatedBasket,
          };
        });

        setFilteredData(processedData);
        setOrder(processedData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const handleSearchWrapper = (searchTerm) => {
    const filtered = handleSearch(order, searchTerm);
    setFilteredData(filtered);
  };

  const sort_id = () => {
    const sort = filteredData.sort((a, b) => {
      if (idToggle) {
        return b.id - a.id;
      } else {
        return a.id - b.id;
      }
    });

    setFilteredData(sort);
    setIdToggle(!idToggle);
  };

  const sort_phone = () => {
    const sortedPhone = handleSort({
      data: filteredData,
      toggle: phoneToggle,
      sortBy: "phone",
    });
    setFilteredData(sortedPhone);
    setPhoneToggle(!phoneToggle);
  };

  const sort_name = () => {
    const sorted_name = handleSort({
      data: filteredData,
      toggle: nameToggle,
      sortBy: "name",
    });
    setFilteredData(sorted_name);
    setNameToggle(!nameToggle);
  };

  const sort_email=()=>{
    const sort = handleSort({
      data: filteredData,
      toggle: emailToggle,
      sortBy: "email",
    });
    setFilteredData(sort);
    setEmailToggle(!emailToggle);
  }

  const sort_price_month = () => {
    const sorted_price = handleSort({
      data: filteredData,
      toggle: priceMonthToggle,
      sortBy: "priceMonth",
    });
    setPriceMonthToggle(!priceMonthToggle);
    setFilteredData(sorted_price);
  };

  const sort_date = () => {
    const sorted_date = handleSort({
      data: filteredData,
      toggle: dateToggle,
      sortBy: "date",
    });
    setDateToggle(!dateToggle);
    setFilteredData(sorted_date);
  };

  const sort_value = () => {
    const sorted_value = handleSort({
      data: filteredData,
      toggle: valueToggle,
      sortBy: "value",
    });
    setValueToggle(!valueToggle);
    setFilteredData(sorted_value);
  };

  const sort_month = () => {
    const sorted_month = handleSort({
      data: filteredData,
      toggle: selectedMonthToggle,
      sortBy: "selectedMonth",
    });
    setSelectedMonthToggle(!selectedMonthToggle);
    setFilteredData(sorted_month);
  };

  // Pagination
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const offset = currentPage * itemsPerPage;
  const currentData = filteredData.slice(offset, offset + itemsPerPage);

  const selectedFunc = (e) => {
    const selected = e.target.value;
    setSelectSelected(selected);
  };


  return (
    <>
      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 class="mb-sm-0">Order</h4>

                  <div class="page-title-right">
                    <ol class="breadcrumb m-0">
                      <li class="breadcrumb-item">
                        <a href="javascript: void(0);">Dashboard</a>
                      </li>
                      <li class="breadcrumb-item active">Order</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title mb-0">Add, Edit & Remove</h4>
                  </div>

                  <div class="card-body">
                    <div class="listjs-table" id="customerList">
                      <div class="row g-4 mb-3">
                        <div class="col-sm-auto">
                          <div>
                            <button
                              type="button"
                              class="btn btn-success add-btn me-2"
                              data-bs-toggle="modal"
                              id="create-btn"
                              data-bs-target="#showModal"
                            >
                              <i class="ri-add-line align-bottom me-1"></i> Add
                            </button>
                            <button
                              class="btn btn-soft-danger"
                              onClick="deleteMultiple()"
                            >
                              <i class="ri-delete-bin-2-line"></i>
                            </button>
                          </div>
                        </div>
                        <div class="col-sm">
                          <div class="d-flex justify-content-sm-end">
                            <div className="result">
                              <select
                                name=""
                                id=""
                                className="h-100 px-2"
                                onChange={selectedFunc}
                              >
                                <option value="6">6</option>
                                <option value="12">12</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                              </select>
                            </div>
                            <div class="search-box ms-2">
                              <input
                                type="text"
                                class="form-control search"
                                placeholder="Search..."
                                onChange={(e) =>
                                  handleSearchWrapper(e.target.value)
                                }
                              />
                              <i class="ri-search-line search-icon"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="table-responsive table-card mt-3 mb-1">
                        <table
                          class="table align-middle table-nowrap"
                          id="customerTable"
                        >
                          <thead class="table-light">
                            <tr>
                              {/* <th scope="col" style={{ width: "50px" }}>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="checkAll"
                                  value="option"
                                />
                              </div>
                            </th> */}
                              {/* <th style={{ cursor: "pointer" }} onClick={sort_id}>
                              <div className="d-flex justify-content-between">
                                Order id
                                <div className="d-flex flex-column ms-2 mt-1">
                                  <i
                                    class="fa-solid fa-caret-up"
                                    style={{
                                      fontSize: "10px",
                                      color: `${idToggle ? "#00C2CB" : "#000"}`,
                                    }}
                                  ></i>
                                  <i
                                    class="fa-solid fa-caret-down"
                                    style={{
                                      fontSize: "10px",
                                      color: `${
                                        idToggle !== true ? "#00C2CB" : "#000"
                                      }`,
                                      transform: "translateY(-4px)",
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </th> */}

                              <th
                                style={{ cursor: "pointer" }}
                                onClick={sort_id}
                              >
                                <div className="d-flex justify-content-between">
                                  Order Id
                                  <div className="d-flex  flex-column ms-2 mt-1">
                                    <i
                                      class="fa-solid fa-caret-up"
                                      style={{
                                        fontSize: "10px",
                                        color: "#00C2CB",
                                      }}
                                    ></i>
                                    <i
                                      class="fa-solid fa-caret-down"
                                      style={{
                                        fontSize: "10px",
                                        color: "#000",
                                        transform: "translateY(-4px)",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </th>
                              <th
                                style={{ cursor: "pointer" }}
                                onClick={sort_name}
                              >
                                <div className="d-flex justify-content-between">
                                  Ad
                                  <div className="d-flex  flex-column ms-2 mt-1">
                                    <i
                                      class="fa-solid fa-caret-up"
                                      style={{
                                        fontSize: "10px",
                                        color: "#00C2CB",
                                      }}
                                    ></i>
                                    <i
                                      class="fa-solid fa-caret-down"
                                      style={{
                                        fontSize: "10px",
                                        color: "#000",
                                        transform: "translateY(-4px)",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </th>
                              <th
                                style={{ cursor: "pointer" }}
                                onClick={sort_name}
                              >
                                <div className="d-flex justify-content-between">
                                  Soyad
                                  <div className="d-flex  flex-column ms-2 mt-1">
                                    <i
                                      class="fa-solid fa-caret-up"
                                      style={{
                                        fontSize: "10px",
                                        color: `${
                                          valueToggle ? "#00C2CB" : "#000"
                                        }`,
                                      }}
                                    ></i>
                                    <i
                                      class="fa-solid fa-caret-down"
                                      style={{
                                        fontSize: "10px",
                                        color: `${
                                          valueToggle !== true
                                            ? "#00C2CB"
                                            : "#000"
                                        }`,
                                        transform: "translateY(-4px)",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </th>

                              <th
                                style={{ cursor: "pointer" }}
                                onClick={sort_phone}
                              >
                                <div className="d-flex justify-content-between">
                                  Mobil
                                  <div className="d-flex  flex-column ms-2 mt-1">
                                    <i
                                      class="fa-solid fa-caret-up"
                                      style={{
                                        fontSize: "10px",
                                        color: "#00C2CB",
                                      }}
                                    ></i>
                                    <i
                                      class="fa-solid fa-caret-down"
                                      style={{
                                        fontSize: "10px",
                                        color: "#000",
                                        transform: "translateY(-4px)",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </th>
                              <th
                                style={{ cursor: "pointer" }}
                                onClick={sort_email}
                              >
                                <div className="d-flex justify-content-between">
                                  Email
                                  <div className="d-flex  flex-column ms-2 mt-1">
                                    <i
                                      class="fa-solid fa-caret-up"
                                      style={{
                                        fontSize: "10px",
                                        color: "#00C2CB",
                                      }}
                                    ></i>
                                    <i
                                      class="fa-solid fa-caret-down"
                                      style={{
                                        fontSize: "10px",
                                        color: "#000",
                                        transform: "translateY(-4px)",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </th>
                              <th
                                style={{ cursor: "pointer" }}
                              >
                                <div className="d-flex justify-content-between">
                                  Çatdırılma üsulu
                                  <div className="d-flex  flex-column ms-2 mt-1">
                                    <i
                                      class="fa-solid fa-caret-up"
                                      style={{
                                        fontSize: "10px",
                                        color: `${
                                          priceMonthToggle ? "#00C2CB" : "#000"
                                        }`,
                                      }}
                                    ></i>
                                    <i
                                      class="fa-solid fa-caret-down"
                                      style={{
                                        fontSize: "10px",
                                        color: `${
                                          priceMonthToggle !== true
                                            ? "#00C2CB"
                                            : "#000"
                                        }`,
                                        transform: "translateY(-4px)",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </th>
                              <th
                                style={{ cursor: "pointer" }}
                               
                              >
                                <div className="d-flex justify-content-between">
                                  Məhsul adı
                                  <div className="d-flex  flex-column ms-2 mt-1">
                                    <i
                                      class="fa-solid fa-caret-up"
                                      style={{
                                        fontSize: "10px",
                                        color: `${
                                          selectedMonthToggle
                                            ? "#00C2CB"
                                            : "#000"
                                        }`,
                                      }}
                                    ></i>
                                    <i
                                      class="fa-solid fa-caret-down"
                                      style={{
                                        fontSize: "10px",
                                        color: `${
                                          selectedMonthToggle !== true
                                            ? "#00C2CB"
                                            : "#000"
                                        }`,
                                        transform: "translateY(-4px)",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </th>
                              <th
                                style={{ cursor: "pointer" }}
                               
                              >
                                <div className="d-flex justify-content-between">
                                  Miqdar
                                  <div className="d-flex  flex-column ms-2 mt-1">
                                    <i
                                      class="fa-solid fa-caret-up"
                                      style={{
                                        fontSize: "10px",
                                        color: `${
                                          selectedMonthToggle
                                            ? "#00C2CB"
                                            : "#000"
                                        }`,
                                      }}
                                    ></i>
                                    <i
                                      class="fa-solid fa-caret-down"
                                      style={{
                                        fontSize: "10px",
                                        color: `${
                                          selectedMonthToggle !== true
                                            ? "#00C2CB"
                                            : "#000"
                                        }`,
                                        transform: "translateY(-4px)",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </th>

                              <th
                                style={{ cursor: "pointer" }}
                                //
                              >
                                <div className="d-flex justify-content-between">
                                  Qiymət
                                  <div className="d-flex  flex-column ms-2 mt-1">
                                    <i
                                      class="fa-solid fa-caret-up"
                                      style={{
                                        fontSize: "10px",
                                        color: `${
                                          selectedMonthToggle
                                            ? "#00C2CB"
                                            : "#000"
                                        }`,
                                      }}
                                    ></i>
                                    <i
                                      class="fa-solid fa-caret-down"
                                      style={{
                                        fontSize: "10px",
                                        color: `${
                                          selectedMonthToggle !== true
                                            ? "#00C2CB"
                                            : "#000"
                                        }`,
                                        transform: "translateY(-4px)",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </th>
                              <th
                                style={{ cursor: "pointer" }}
                                //
                              >
                                <div className="d-flex justify-content-between">
                                  Ödəniş
                                  <div className="d-flex  flex-column ms-2 mt-1">
                                    <i
                                      class="fa-solid fa-caret-up"
                                      style={{
                                        fontSize: "10px",
                                        color: `${
                                          selectedMonthToggle
                                            ? "#00C2CB"
                                            : "#000"
                                        }`,
                                      }}
                                    ></i>
                                    <i
                                      class="fa-solid fa-caret-down"
                                      style={{
                                        fontSize: "10px",
                                        color: `${
                                          selectedMonthToggle !== true
                                            ? "#00C2CB"
                                            : "#000"
                                        }`,
                                        transform: "translateY(-4px)",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </th>
                              <th
                                style={{ cursor: "pointer" }}
                                //
                              >
                                <div className="d-flex justify-content-between">
                                  Tarix
                                  <div className="d-flex  flex-column ms-2 mt-1">
                                    <i
                                      class="fa-solid fa-caret-up"
                                      style={{
                                        fontSize: "10px",
                                        color: `${
                                          selectedMonthToggle
                                            ? "#00C2CB"
                                            : "#000"
                                        }`,
                                      }}
                                    ></i>
                                    <i
                                      class="fa-solid fa-caret-down"
                                      style={{
                                        fontSize: "10px",
                                        color: `${
                                          selectedMonthToggle !== true
                                            ? "#00C2CB"
                                            : "#000"
                                        }`,
                                        transform: "translateY(-4px)",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </th>
                              <th
                                style={{ cursor: "pointer" }}
                                //
                              >
                                <div className="d-flex justify-content-between">
                                  Qeyd :
                                  <div className="d-flex  flex-column ms-2 mt-1">
                                    <i
                                      class="fa-solid fa-caret-up"
                                      style={{
                                        fontSize: "10px",
                                        color: `${
                                          selectedMonthToggle
                                            ? "#00C2CB"
                                            : "#000"
                                        }`,
                                      }}
                                    ></i>
                                    <i
                                      class="fa-solid fa-caret-down"
                                      style={{
                                        fontSize: "10px",
                                        color: `${
                                          selectedMonthToggle !== true
                                            ? "#00C2CB"
                                            : "#000"
                                        }`,
                                        transform: "translateY(-4px)",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody class="list form-check-all">
                            {loading ? (
                              <tr>
                                <td colSpan="8" className="text-center">
                                  <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ minHeight: "100px" }}
                                  >
                                    <div className="spinner ms-5"></div>
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              currentData.length > 0 &&
                              currentData.map((item, i) => {
                                const productNames = JSON.parse(
                                  item.productName || "[]"
                                );
                                const productNameString =
                                  productNames.join(", ");
                                const productPrice = JSON.parse(
                                  item.price || "[]"
                                );
                                const productPriceString =
                                  productPrice.join(", ");
                                const time_milliseconds = Number(
                                  item.date + "000"
                                );
                                const date = new Date(time_milliseconds);
                                const formatted_date =
                                  date.toLocaleDateString();
                                return (
                                  <tr key={i}>
                                    <td>
                                      <a
                                        href="#"
                                        className="fw-medium link-primary"
                                      >
                                        #{item.id}
                                      </a>
                                    </td>
                                    <td>{item.firstName}</td>
                                    <td>{item.lastName}</td>
                                    <td>{item.mobile}</td>
                                    <td>{item.email}</td>
                                    <td>{item.address == "" ? item.store == "store" ? "Mağazadan əldə etmək" : "" : item.address}</td>
                                    <td>{productNameString}</td>
                                    <td>
                                      {item.basket.map((i) => {
                                       return i.count
                                      })}
                                    </td>
                                    <td>
                                      {item.basket
                                        .map((i) => {
                                          const count = parseFloat(i.count); 
                                          const productPrice =
                                            parseFloat(productPriceString); 
                                          return count * productPrice; 
                                        })
                                        .reduce(
                                          (acc, curr) => acc + curr,
                                          0
                                        ).toFixed(2)}
                                      ₼
                                    </td>
                                    <td>{item.payment === "cash" ? "Nağd ödəniş" : "Onlayn ödəniş"}</td>
                                    <td>{formatted_date}</td>
                                    <td>{item.note}</td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                        {loading
                          ? ""
                          : filteredData.length == 0 && (
                              <div
                                class="noresult"
                                style={{ display: "block" }}
                              >
                                <div class="text-center">
                                  <lord-icon
                                    src="https://cdn.lordicon.com/unukghxb.json"
                                    trigger="loop"
                                    delay="500"
                                    style={{ width: "100px", height: "100px" }}
                                  ></lord-icon>
                                  <h5 class="mt-2">Sorry! No Result Found</h5>
                                  <p class="text-muted mb-0">
                                    We've searched more than 150+ Orders We did
                                    not find any orders for you search.
                                  </p>
                                </div>
                              </div>
                            )}
                      </div>
                      {filteredData.length !== 0 && (
                        <div className="d-flex justify-content-between">
                          <div>
                            Showing 1-{selectSelected} of {filteredData.length}{" "}
                            results
                          </div>
                          <div class="">
                            <Pagination
                              pageCount={Math.ceil(
                                filteredData.length / itemsPerPage
                              )}
                              onPageChange={handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal fade"
              id="showModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Edit
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="close-modal"
                    ></button>
                  </div>
                  {selected !== undefined && (
                    <form class="tablelist-form" autocomplete="off">
                      <div class="modal-body">
                        <div
                          class="mb-3"
                          id="modal-id"
                          style={{ display: "none" }}
                        >
                          <label for="id-field" class="form-label">
                            ID
                          </label>
                          <input
                            type="text"
                            id="id-field"
                            class="form-control"
                            placeholder="ID"
                            readonly
                          />
                        </div>

                        <div class="mb-3">
                          <label for="customername-field" class="form-label">
                            Id
                          </label>
                          <input
                            type="text"
                            id="customername-field"
                            class="form-control"
                            value={selected.id}
                            placeholder="Enter Name"
                            required
                          />
                          <div class="invalid-feedback">
                            Please enter a customer name.
                          </div>
                        </div>

                        <div class="mb-3">
                          <label for="customername-field" class="form-label">
                            Ad
                          </label>
                          <input
                            type="text"
                            id="customername-field"
                            class="form-control"
                            value={selected.name}
                            placeholder="Enter Name"
                            required
                            onChange={(e) => {
                              if (selected) {
                                setSelected({
                                  ...selected,
                                  name: e.target.value,
                                });
                              }
                            }}
                          />
                          <div class="invalid-feedback">
                            Please enter a customer name.
                          </div>
                        </div>
                        <div class="mb-3">
                          <label for="phone-field" class="form-label">
                            Mobil
                          </label>
                          <input
                            value={selected.phone}
                            type="text"
                            id="phone-field"
                            class="form-control"
                            placeholder="Enter Phone no."
                            required
                          />
                          <div class="invalid-feedback">
                            Please enter a phone.
                          </div>
                        </div>
                        <div class="mb-3">
                          <label for="email-field" class="form-label">
                            İlkin ödəniş
                          </label>
                          <input
                            type="email"
                            value={`$${selected.value}`}
                            id="email-field"
                            class="form-control"
                            placeholder="Enter Email"
                            required
                          />
                          <div class="invalid-feedback">
                            Please enter an email.
                          </div>
                        </div>
                        <div class="mb-3">
                          <label for="email-field" class="form-label">
                            Aylara görə
                          </label>
                          <input
                            type="email"
                            value={`$${selected.priceMonth}`}
                            id="email-field"
                            class="form-control"
                            placeholder="Enter Email"
                            required
                          />
                          <div class="invalid-feedback">
                            Please enter an email.
                          </div>
                        </div>
                        <div class="mb-3">
                          <label for="email-field" class="form-label">
                            Seçilən ay
                          </label>
                          <input
                            type="email"
                            value={`${selected.selectedMonth}`}
                            id="email-field"
                            class="form-control"
                            placeholder="Enter Email"
                            required
                          />
                          <div class="invalid-feedback">
                            Please enter an email.
                          </div>
                        </div>
                        <div class="mb-3">
                          <label for="date-field" class="form-label">
                            Tarix
                          </label>
                          <input
                            value={selected.date}
                            type="text"
                            id="date-field"
                            class="form-control"
                            placeholder="Select Date"
                            required
                          />
                          <div class="invalid-feedback">
                            Please select a date.
                          </div>
                        </div>

                        {/* <div>
                        <label for="status-field" class="form-label">
                          Status
                        </label>
                        <select
                          class="form-control"
                          data-trigger
                          name="status-field"
                          id="status-field"
                          required
                        >
                          <option value={`${selected.active}`} selected>{selected.active}</option>
                        </select>
                      </div> */}
                      </div>
                      <div class="modal-footer">
                        <div class="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            class="btn btn-light"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            class="btn btn-success"
                            id="edit-btn"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>

            <div
              class="modal fade zoomIn"
              id="deleteRecordModal"
              tabindex="-1"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="btn-close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div class="mt-2 text-center">
                      <lord-icon
                        src="https://cdn.lordicon.com/gsqxdxog.json"
                        trigger="loop"
                        colors="primary:#f7b84b,secondary:#f06548"
                        style={{ width: "100px", height: "100px" }}
                      ></lord-icon>
                      <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                        <h4>Are you Sure ?</h4>
                        <p class="text-muted mx-4 mb-0">
                          Are you Sure You want to Remove this Record ?
                        </p>
                      </div>
                    </div>
                    <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                      <button
                        type="button"
                        class="btn w-sm btn-light"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        class="btn w-sm btn-danger "
                        id="delete-record"
                      >
                        Yes, Delete It!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderVertu;
